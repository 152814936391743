import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
// fontawesome icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// bootstrap-vue components
import BootstrapVue from 'bootstrap-vue';
// #
// vue portal
import PortalVue from 'portal-vue';
// v-calendar
import VCalendar from 'v-calendar';
// vee validate
import {
  extend, localize,
  ValidationObserver, ValidationProvider,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
// vue-the-mask
import VueTheMask from 'vue-the-mask';
// toast notifications
import Toasted from 'vue-toasted';

import {
  cnpj, cnpjDisponivel,
  codigoSetor,
  cpf, cpfDisponivel,
  length, tempoMaiorQue,
  tempoMenorQue,
} from '@/rules/Validator';

// ---------------
import App from './App.vue';
import Button from './components/Button/Button.vue';
import IconButton from './components/Button/IconButton.vue';
import SaveButton from './components/Button/SaveButton.vue';
import Editor from './components/Editor.vue';
import Checkbox from './components/Form/Checkbox.vue';
import DatePicker from './components/Form/DatePicker.vue';
import FormFile from './components/Form/FormFile.vue';
import NumericInput from './components/Form/NumberInput.vue';
import Picker from './components/Form/Picker.vue';
import PickerList from './components/Form/PickerList.vue';
import Radio from './components/Form/Radio.vue';
import Select from './components/Form/Select.vue';
import SelectPicker from './components/Form/SelectPicker.vue';
import Textarea from './components/Form/Textarea.vue';
import TextInput from './components/Form/TextInput.vue';
import TimePicker from './components/Form/TimePicker.vue';
import Loader from './components/Loader.vue';
import ConfirmationModal from './components/Modal/ConfirmationModal.vue';
import PagedTable from './components/PagedTable.vue';
import BackLink from './components/Router/BackLink.vue';
import Link from './components/Router/Link.vue';
import SmallLoading from './components/SmallLoading.vue';
import Table from './components/Table.vue';
import TimeLine from './components/TimeLine.vue';
import Timer from './components/Timer.vue';
// soitic components
import Title from './components/Title.vue';
import TypePrint from './components/TypePrint.vue';
import router from './router';
import store from './store';
/* tslint:disable:no-var-requires */
const ptBr = require('vee-validate/dist/locale/pt_BR.json');

// register portal vue component
Vue.use(PortalVue);

// register bootstrap vue components;
Vue.use(BootstrapVue);

// register toasted notifications
Vue.use(Toasted);

// v-calendar
Vue.use(VCalendar);

// ApexCharts
Vue.use(VueApexCharts);

// register validations rules and validations components
Object.entries(rules).forEach(([rule, validation]) => {
  extend(rule, { ...(validation as object) });
});

extend('length', length);
extend('cpf', cpf);
extend('cnpj', cnpj);
extend('tempoMenorQue', tempoMenorQue);
extend('tempoMaiorQue', tempoMaiorQue);
extend('cnpjDisponivel', cnpjDisponivel);
extend('cpfDisponivel', cpfDisponivel);
extend('codigoSetor', codigoSetor);

localize('pt_BR', ptBr);

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);

// vue-the-mask
Vue.use(VueTheMask);
Vue.directive('mask', VueTheMask.mask);
// register fontawesome icons and FontAwesomeIcon component;
library.add(fas);
library.add(fab);

Vue.component('fa-icon', FontAwesomeIcon);

// soitic components
Vue.component('soi-title', Title);

Vue.component('soi-text-input', TextInput);
Vue.component('soi-number-input', NumericInput);
Vue.component('soi-select', Select);
Vue.component('soi-form-file', FormFile);
Vue.component('soi-checkbox', Checkbox);
Vue.component('soi-radio', Radio);
Vue.component('soi-picker', Picker);
Vue.component('soi-textarea', Textarea);
Vue.component('soi-date-picker', DatePicker);
Vue.component('soi-time-picker', TimePicker);
Vue.component('soi-select-picker', SelectPicker);
Vue.component('soi-picker-list', PickerList);

Vue.component('soi-table', Table);
Vue.component('soi-paged-table', PagedTable);

Vue.component('soi-timeline', TimeLine);

Vue.component('soi-button', Button);
Vue.component('soi-save-button', SaveButton);
Vue.component('soi-icon-button', IconButton);

Vue.component('soi-link', Link);
Vue.component('soi-back-link', BackLink);
Vue.component('soi-loader', Loader);
Vue.component('soi-small-loading', SmallLoading);

Vue.component('soi-confirmation-modal', ConfirmationModal);

Vue.component('soi-timer', Timer);

Vue.component('soi-editor', Editor);

Vue.component('soi-type-print', TypePrint);

Vue.component('apex-chart', VueApexCharts);

Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h: (arg0: any) => any) => h(App),
}).$mount('#app');
